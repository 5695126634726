<template>
  <a-layout>
    <a-layout-content>
      <a-row type="flex" align="middle" style="height: calc(100vh - 62px)">
        <a-col class="login">
          <div v-if="isCallback">
            <a-spin class="spin" tip="Logging in..."> </a-spin>
          </div>
          <div v-else class="form">
            <img src="../assets/springup-logo.svg" alt="" class="logo" />
            <div class="title">WorkStats</div>
            <button
              class="login-with-gitlab"
              type="primary"
              size="large"
              @click.stop.prevent="onLogin"
            >
              <img src="../assets/gitlab-icon.svg" alt="" />
              <span>Login with GitLab</span>
            </button>
          </div>
        </a-col>
      </a-row>
    </a-layout-content>
    <a-layout-footer class="footer">&copy;2022 Springup Inc.</a-layout-footer>
  </a-layout>
</template>
<script>
import { inject, reactive, toRefs, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { services } from "../../config";
import { randomString } from "../utils/utils";
import { useQuery } from "@vue/apollo-composable";

import jwt from "jsonwebtoken";
export default {
  components: {},
  setup() {
    const v = reactive({
      loading: false,
      isCallback: null,
    });
    const { $filters, gql, $msg } = inject("$");

    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    onBeforeMount(async () => {
      if (route.query && route.query.code && route.query.state) {
        v.isCallback = true;
        await exchangeToken(route.query.code, route.query.state);
        return;
      }
    });

    let qloading;
    async function exchangeToken(code, state) {
      try {
        const session_id = localStorage.getItem("session_id");

        let url = `${services.server}/api/v1/auth?`;

        const res = await fetch(
          url + new URLSearchParams({ code, state, session_id })
        );

        if (res.status == 200) {
          $msg.success("Login success");
          let data = await res.json();
          console.log(data);
          if (data) {
            let str = jwt.decode(data.accessToken);
            console.log(str);

            if (str) {
              let obj = str;
              obj.token = data.accessToken;
              obj.refreshToken = data.refreshToken;
              obj.exp = obj.exp * 1000;
              obj.user = str.user;

              let roles = [];
              if (str.user.isAdmin) {
                roles.push("ADMIN");
              } else {
                roles.push("USER");
              }
              obj.user.roles = roles;
              localStorage.setItem("_tokenInfo", JSON.stringify(obj));
              localStorage.setItem("_userInfo", JSON.stringify(obj.user));
              localStorage.setItem("_gitlabId", obj.user.id);
              localStorage.setItem("committer_id", obj.user.id);

              store.commit("SET_TOKENINFO", obj);
              store.commit("SET_LOGIN_STATUS", true);
              store.commit("SET_USERNAME", str.user.username);
              store.commit("SET_USERINFO", str.user);
              // store.commit("SET_SUPERUSER", str.super_user);
                // 登陆以前是用数组决定用户role，目前固定admin和manager，待优化


              store.commit("SET_ROLES", roles);

              // let isAdmin = str.user.roles.some(
              //   (item) => item == "MANAGER" || item == "ADMIN"
              // );
              let isAdmin = str.user.isAdmin;

              if (isAdmin) {
                router.push("/admin");
              } else {
                router.push("/");
              }
            } else {
              $msg.error("Login failed");
            }
          }
        } else {
          $msg.error("Failed!");
        }
      } catch (err) {
        console.log(err);
        $msg.error("Network error please try again later");
      } finally {
        // localStorage.removeItem("session_id");
      }
    }
    async function onLogin() {
      let session_id = randomString(16);
      localStorage.setItem("session_id", session_id);

      window.location.href = `${services.server}/api/v1/login/gitlab?session_id=${session_id}`;
    }

    return {
      ...toRefs(v),
      qloading,
      onLogin,
    };
  },
};
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  max-width: 391px;
  padding: 25px 0;
  margin: auto;
  text-align: center;
  border-radius: 5px;

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
      width: 256px;
      margin-bottom: 100px;
    }

    .title {
      font-size: 36px;
      margin-bottom: 20px;
      font-weight: 900;
      line-height: 42px;
    }

    .login-with-gitlab {
      width: 100%;
      background-color: #292961;
      border: #292961;
      font-size: 20px;
      font-weight: 600;
      color: #ffffff;
      border-radius: 2px;

      cursor: pointer;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      img {
        height: 45px;
      }
    }
  }

  .spin {
    left: 50%;
  }
}

@media (min-width: 992px) {
  .login {
    padding: 30px 30px;
  }
}

.footer {
  text-align: center;
}
</style>
